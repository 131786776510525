
import React, { createContext, useState } from "react"

export const SidebarContext = createContext({
  sidebarHidden: false,
  setSidebarHidden: () => {}
})

export const SidebarProvider = ({ children }) => {
  const [sidebarHidden, setSidebarHidden] = useState(false)
  return (
    <SidebarContext.Provider value={{ sidebarHidden, setSidebarHidden }}>
      {children}
    </SidebarContext.Provider>
  )
}